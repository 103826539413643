.item-top-section {
  height: 389px !important;
  background: radial-gradient(
    74.37% 105.56% at 71.01% 57.97%,
    #ffffff 0%,
    #fbebd3 74%,
    #fbebd3 100%
  );
}

.pincode-input label {
  display: block;
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}


.input-container {
  display: flex;
  align-items: center;
}

.input-container input {
  width: 100%;
  max-width: 120px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Kingred Modern";
  font-size: 14px;
  -moz-appearance: textfield;
}

.input-container input::-webkit-outer-spin-button,
.input-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.loader-container .MuiCircularProgress-root {
  color: #ffd404;
}

.check {
  width: 80px;
  height: 40px;
  margin-left: 10px;
  background-color: #ffd404;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Kingred Modern";
  font-size: 16px;
  padding: 0 10px;
}

.input-container .btn-check:hover {
  background-color: #ffd404;
}

.product-imgg img,
.test2 {
  height: 174px;
  object-fit: cover;
}

.product-imgg video {
  object-fit: cover;
}

.deatils-layer {
  height: 856px;
  background-color: #ffffff;
  box-shadow: 0px 37px 90px 0px #00000017;
}

.content-one h1 {
  font-family: "Kingred Modern";
  font-size: 26px;
  font-weight: 500;
  line-height: 35.41px;
  text-align: left;
  text-transform: capitalize;
}

.content-one h6 {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  text-align: left;
}

.content-one span {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
}

.content-one p {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 400;
  line-height: 22.49px;
  text-align: left;
  text-transform: capitalize;
}

#lbbtn {
  background-color: #ffd404;
}

.item-btn {
  display: inline-block;
  text-decoration: none;
  color: black;
  /* border: 1px solid black; */
  padding: 5px 10px;
  background-color: #f2f2f2;
  cursor: pointer;
  font-family: "Kingred Modern";
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}

.item-btn:hover {
  background-color: #ffd404;
}

.item-btn.selected {
  background-color: #ffd404;
  width: 100% !important;
}

.purchase-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.content-two {
  overflow: hidden;
}

.size-options,
.color-options {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  max-width: 100%;
}

.content-two h6 {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
}

.content-three span {
  font-family: "Kingred Modern";
  font-size: 22px;
  font-weight: 500;
  line-height: 32.78px;
  text-align: left;
}

.content-three p {
  /* font-family: "Kingred Modern"; */
  font-size: 12px;
  font-weight: 400;
  line-height: 22.49px;
  text-align: left;
  color: #000;
  opacity: 0.5;
}

.purchase-btn {
  text-decoration: none;
  font-family: "Kingred Modern";
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  color: #000;
  background-color: #ffd404;
  /* margin-bottom: 10px; */
}

.Best-sellings {
  position: relative;
}

.no-products-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.5rem;
  color: #ffd404;
}

#smpdh h1 {
  font-family: "Kingred Modern";
  font-size: 35px;
  font-weight: 500;
  line-height: 44.45px;
  text-align: left;
  color: #000;
  position: relative;
}

.item-top-section {
  height: 180px;
}

#smpdbtn {
  position: relative;
  left: 115px;
}

.gpgp {
  position: relative;
  top: -4px;
}

.ctnrev {
  background-color: #fefcf3;
  padding: 52px;
  position: relative;
}

.best-seslling-flowerr {
  position: absolute;
  left: 7px;
  top: 50px;
}

.placeholder-card {
  visibility: hidden;
  height: 0;
  width: 100%;
}

.card-similar {
  margin: 0px 8px;
}

.slider-image {
  border: 1px solid #b5b5b5;
  width: 292.44px;
  height: 360.59px;
  object-fit: cover;
}

.small-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.pdlstcol img {
  width: 100%;
  height: auto;
  /* border: 1px solid #ccc; */
  padding: 5px;
}

.small-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.lftprd {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.rgtprd {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.des {
  margin-bottom: -32px !important;
  height: 84px;
}

#jkjk {
  position: relative;
}

.hdbtnn {
  position: relative;
  left: -31px;
}

.crlbtnn {
  position: relative;
  left: 31px;
  display: flex;
}

#smppd {
  position: relative;
  top: -270px;
}

/* 
.buy-btn {
    color: white;
    background-color: #FF6B00;
} */

.content-four h6 {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
}

.content-four h6:hover::before,
.content-four .active::before {
  content: "";
  position: absolute;
  top: 34px;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #ffd404;
  z-index: 1;
}

/* .content-four-details {
    height: 241px;
} */

.content-four-details ul {
  padding: 0;
  margin: 0;
  list-style-type: disc;
}

.desc {
  position: relative;
  transition: height 0.3s ease;
}

.read-morre-btn {
  color: #000;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 12px;
  background-color: #ffd404;
}

.description-close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: rgb(255, 72, 66);
  margin-top: 10px;
  background: transparent;
}

.description-close-btn:hover {
  color: #f44336;
  background-color: rgba(255, 72, 66, 0.1);
  box-shadow: 0 4px 8px rgba(255, 72, 66, 0.2);
}

.modal-content-Description {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  z-index: 1000;
}

.para {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 400;
  line-height: 22.76px;
  color: black;
}

.lg-none {
  display: none;
}

.item-Similar-products {
  margin-top: 57%;
}

.card-container .card-similar {
  width: 275.44px !important;
  height: 455.59px;
}

.card-similar img {
  width: 100%;
  height: 360.59px;
  object-fit: cover;
}

.item-card-title {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 400;
  line-height: 23.84px;
  text-align: left;
}

.review h5 {
  /* font-family: "Kingred Modern"; */
  font-size: 20px;
  font-weight: 600;
  line-height: 29.8px;
  text-align: left;
  color: black;
}

.review p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25.76px;
  text-align: left;
  color: black;
}

.rvw {
  padding-bottom: 16px;
}

.review h6 {
  font-family: "Kingred Modern";
  font-size: 17px;
  font-weight: 300;
  line-height: 23.84px;
  text-align: left;
  color: black;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: center;
  font-size: 20px;
}

.rating > span {
  display: inline-block;
  position: relative;
  width: 1.1em;
  cursor: pointer;
}

.rating > span:hover:before,
.rating > span:hover ~ span:before,
.rating > span.active:before,
.rating > span.active ~ span:before {
  content: "\2605";
  position: absolute;
}

.rating > span:hover:before,
.rating > span.active:before {
  color: black;
}

.rating > span:hover ~ span:before,
.rating > span.active ~ span:before {
  color: #ccc;
}

.review_seperator {
  width: 36px !important;
  height: 156px;
  position: relative;
  top: 75px;
  left: -21px;
}

.product-imgg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-imgg img {
  margin-bottom: 15px;
}

.product-imgg video {
  margin-bottom: 25px;
}

.icon-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  z-index: 5;
}

.wishlist-icon {
  font-size: 24px;
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease;
}

.wishlist-icon.clicked {
  color: red;
}

.share-alt-icon {
  font-size: 24px;
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease;
}

.share-options {
  display: flex;
  gap: 10px;
  /* position: absolute; */
  top: -5px;
  right: 0;
  background: white;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
}

.share-icon {
  cursor: pointer;
  font-size: 24px;
  color: #555;
  transition: color 0.3s ease;
}

.share-icon:hover {
  color: #007bff;
}

.bs-card-title {
  font-family: "Kingred Modern";
  font-weight: 400;
  font-size: 16px;
  line-height: 23.84px;
  margin: 0;
  color: #000;
}

.bs-price {
  font-family: "Kingred Modern";
  font-size: 18px;
  font-weight: 500;
  line-height: 26.82px;
  text-align: left;
  margin-top: 5px;
  color: #000;
}

.share-icons {
  display: flex;
  gap: 16px;
  background: white;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
}

.share-icon {
  cursor: pointer;
  font-size: 24px;
  color: #555;
  transition: color 0.3s;
}

.share-icon:hover {
  color: #007bff;
}
.product_detail_section {
  background-color: #ffffff;
  box-shadow: 0px 37px 90px 0px #00000017;
  border-radius: 10px;
}

.product_main {
  margin-top: -127px;
  margin-bottom: 30px;
}

.similar_product {
  margin-block: 80px;
}

.media-container video {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .media-container img {
    width: 100px;
  }
  .media-container video {
    width: 100px;
  }
}
