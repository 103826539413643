.view-similar-container {
  padding: 60px;
  text-align: left;
}

.view-similar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-headings {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Kingred Modern';
  text-transform: uppercase;
}

.slider-arrows {
  display: flex;
  gap: 5px;
}

.similar-grid-container {
  width: 100%;
  overflow: hidden;
}

.similar-grid {
  display: flex;
  gap: 30px;
}

.similar-item {
  width: 280px;
  text-align: center;
  flex: 0 0 auto;
  cursor: pointer;
}

.similar-item img {
  width: 290px;
  /* width: 100%; */
  height: 395px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.similar-item:hover img {
  transform: scale(1.05);
}

.view-product-price {
  margin-top: 10px;
}

.view-product-name {
  font-size: 1.2em;
  color: #333;
  font-family: 'Kingred Modern';
  margin: 0;
  /* text-align: left; */
}

.view-product-price {
  font-size: 1em;
  color: #777;
  margin: 5px 0 0 0;
  /* text-align: left; */
}

/* Responsive styles */
@media (max-width: 767px) { /* Small screens */
  .similar-item {
    width: 100%;
  }

  .similar-grid {
    gap: 5px;
  }

  .similar-item img {
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) { /* Medium screens */
  .similar-item {
    width: 48%; /* Two products per row */
  }

  .similar-item img {
    height: 350px;
  }
}
