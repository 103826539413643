.category-section-container {
  text-align: center;
  padding: 20px;
}

.section-headingg {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  font-family: 'Kingred Modern';
  text-transform: uppercase;
  text-align: left;
}

.bubble_item {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bubble_image {
  object-fit: cover;
  width: 300px;
  height: 400px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.bubble_item:hover .bubble_image {
  transform: scale(1.1);
}

.bubble_text {
  margin: 0;
  font-size: 20px;
  text-align: center;
  line-height: 1.2;
  margin-top: 20px;
  color: #fff;
}

@media (max-width: 768px) {
  .dd {
    justify-content: center !important; 
  }
  
  .bubble_item {
    margin-bottom: 20px; 
  }
}
