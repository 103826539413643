.carrd {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* width: 288.25px;
    height: 595px;
    border-radius: 4px; */
    cursor: pointer;
  }
  
  .carrd .icons-container {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    height: 29.28px;
    width: 111.25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .carrd:hover .icons-container {
    opacity: 1;
  }
  
  .card__thumbnail {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 288.25px;
    height: 411px;
  }
  
  
  .card__thumbnail img {
    position: absolute;
    width: 288.25px;
    height: 411px;
    object-fit: cover;
  }
  
  .card__wishlist {
    position: absolute;
    /* top: 10px; */
    right: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    z-index: 10;
  }
  
  .wishlist-icon {
    position: absolute;
    bottom: 10px;
    right: 70px;
    font-size: 20px;
    color: #000;
    transition: opacity 0.3s, color 0.3s;
    cursor: pointer;
    z-index: 10;
  }
  
  .share-alt-icon {
    position: absolute;
    bottom: 10px;
    right: 30px;
    font-size: 20px;
    color: #000;
    transition: opacity 0.3s, color 0.3s;
    cursor: pointer;
    z-index: 10;
  }
  
  
  .wishlist-icon.clicked {
    color: red;
  }
  
  .card__body {
    padding: 10px;
    height: 183.59px;
  }
  
  .card__title {
    width: 267.75px;
    height: 44.49px;
    margin: 0;
    font-family: "Kingred Modern";
    font-size: 16px;
    line-height: 23.84px;
    text-align: left;
    padding-left: 0;
    text-transform: capitalize;
  }
  .card__desc {
    width: 181px;
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;  
    overflow: hidden; 
    text-overflow: ellipsis; 
    word-wrap: break-word;  
    margin-top: 8px;
    color: #666;
    transition: max-height 0.3s ease;
    text-transform: uppercase;
  }
  
  
  .card__desc,
  .card__price {
    margin: 0;
    font-family: "Kingred Modern";
    font-size: 12px;
    font-weight: 400;
    line-height: 17.88px;
    text-align: left;
    padding-left: 0;
  }
  
  .card__desc {
    margin-top: 8px;
    color: #666;
  }
  
  .card__price {
    margin-top: 8px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .card__button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #262626;
    border: 1px solid #D4D4D4;
    /* border-radius: 4px; */
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .card__button:hover {
    background-color: #ffd404;
  }
  
  .card__button--expand {
    background-color: transparent;
    border: none;
    color: #ffd404;
    font-size: 12px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  .product-link {
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .product-link:hover {
    transform: translateY(-2px);
  }

  .share-iconn {
    cursor: pointer;
    font-size: 24px;
    color: #555;
    transition: color 0.3s ease;
  }

  .share-optionss {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 40px;
    right: -5px;
    background: white;
    border-radius: 5px;
    padding: 5px;
    z-index: 10;
}
