.hero-wrapper {
  margin: 0;
  padding: 100px 0 0 0;
  width: 100%; 
}

.hero-container {
  padding: 0; 
}

.hero-slide img {
  object-fit: cover;
  width: 100%; 
  height: auto; 
}

.carousel .slide {
  background: none; 
}

.carousel {
  width: 100%;
  margin: 0;
}
