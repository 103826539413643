.dash-top-section {
    /* height: 390px; */
    width: 100%;
    background: radial-gradient(
      70.37% 105.56% at 71.01% 57.97%,
      #ffffff 0%,
      #fbebd3 74%,
      #fbebd3 100%
    );
  }
  
  .productt-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 10px; */
    width: 222.2px;
    height: 317.14px;
    object-fit: cover;
  }
  
  .product-item {
    position: relative;
    width: 222.2px;
    height: 466px;
    margin-right: 30px;
  }
  
  .product-item-content {
    position: relative;
    padding: 1rem;
  }
  
  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #000000;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    z-index: 10;
  }
  
  .close-icon i {
    margin: 0;
  }
  
  .scrollable-container {
    max-height: 490px;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  
  .w-containt {
    width: 847px;
    background-color: white;
  }
  
  .w-containt h1 {
    font-family: "Kingred Modern";
    font-size: 18px;
    font-weight: 500;
    line-height: 24.52px;
    text-align: left;
    color: black;
    padding-left: 57px;
    padding-top: 43px;
    padding-bottom: 26px;
  }
  
  .card__buttonn {
    display: block;
    width: 222px;
    padding: 10px;
    background-color: #fff;
    color: #262626;
    border: 1px solid #d4d4d4;
    /* border-radius: 4px; */
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .card__buttonn:hover {
    background-color: #ffd404;
  }
  
  .productlisting-products-wish {
    padding-left: 57px;
  }
  
  .product-img,
  .product-name,
  .product-desc,
  .product-price {
    width: 222.2px;
    margin-right: 32px;
  }
  
  .product-name {
    width: 206.4px;
    height: 50.29px;
    padding-top: 5px;
    font-family: "Kingred Modern";
    font-size: 16px;
    font-weight: 400;
    line-height: 23.84px;
    text-align: left;
    color: #000000;
    text-transform: capitalize;
  }
  
  .product-desc {
    width: 181px;
    margin: 5px 0;
    font-family: "Kingred Modern";
    font-size: 12px;
    font-weight: 400;
    line-height: 17.88px;
    text-align: left;
    color: #7e7e7e;
    /* height: 18px; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    transition: max-height 0.3s ease;
    text-transform: uppercase;
  }
  
  .product-price {
    width: 89px;
    /* height: 27px; */
    font-family: "Kingred Modern";
    font-size: 18px;
    font-weight: 500;
    line-height: 26.82px;
    text-align: left;
    color: black;
    margin: 10px 0;
  }
  
  .btn-lg {
    --bs-btn-border-radius: none;
  }
  
  .custom-dropdown-icon {
    padding-left: 53%;
  }
  
  @media only screen and (min-width: 360px) and (max-width: 480px) {
    .dash-container {
      width: 100%;
    }
  
    .w-containt {
      width: 85%;
      margin: 0 auto;
    }
  
    .w-containt h1 {
      padding: 26px 0 23px 23px !important;
      margin-bottom: 0 !important;
    }
  
    .productlisting-products-wish {
      padding-left: 0;
      margin: 0 auto;
    }
  
    .userActflexx {
      flex-wrap: wrap;
    }
    .product-item {
      margin: 0 auto;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .userActflexx {
      flex-wrap: wrap;
    }
    .dash-container {
      width: 100%;
    }
    .d-containt {
      width: 85%;
      margin: 0 auto;
      padding: 24px;
      box-shadow: 0px 37px 90px 0px #00000017;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 900px) {
    .w-containt {
      width: 85%;
      margin: 0 auto;
    }
  
    .close-icon {
      left: 192px;
    }
  }
  