.top-section {
  height: 340px;
  width: 100%;
  /* background: #f4e7b8; */
}

.product-listing {
  position: relative;
}

.row-gap-5 {
  margin-right: -60px !important;
}
.product-list{
  margin-bottom: 40px;
}

.productlisting-header {
  position: absolute;
  top: -220px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  padding: 90px 0 ;
  box-shadow: 0px 35px 40.30px rgba(0, 0, 2, 0.10);
  background-image: url(../Assets/productlist_bg.avif);
  background-size: cover;
  background-position: center;
}

.productlisting-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.productlisting-header h6 {
  z-index: 1;
  font-family: 'Kingred Modern';
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.14em;
  text-align: center;
  color: #fff;
}

.gpprd {
  position: relative;
  left: -11px;
  top: 8px;
}

.fspace {
  padding-bottom: 46px !important;
}

#pds .card img {
  object-fit: cover !important;
  background-color: #F2F2F2;
}

#pds .card {
  width: 265px !important;
  height: 510px;
  margin: 0 13px !important;
}

#ddss h5 {
  margin-bottom: 0px !important;
}

.form-check-input:checked {
  background-color: #FF6B00;
  border-color: #FF6B00;

}

.productlisting-header h2 {
  z-index: 1;
  font-family: 'Kingred Modern';
  font-size: 40px;
  font-weight: 700;
  line-height: 50.8px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}
.productlisting-header {
  margin-bottom: 180px;
}

.productlisting-indexSort {
  margin-top: 100px;
  /* padding: 0; */
}

.productlisting-indexSort h6 {
  font-family: 'Kingred Modern';
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.custom-btn1 {
  background-color: #fff;
  border: 1px solid #D4D4D4;
  height: 41.68px;
  --bs-btn-border-radius: none;
}

/* .filter-btn {
  display: none;
  width: 20px;
  height: 20px;
} */

#sortDropdown {
  position: relative;
  z-index: 10;
}

.accordion {
  box-shadow: 0px 14px 38.599001525878906px 0px #00000017;
  --bs-accordion-border-color: none;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
}

.btn.dropdown-toggle.custom-btn1 {
  font-family: 'Kingred Modern';
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  text-align: center;
}

.btn-group .dropdown-toggle::after {
  display: none; 
}

.btn.dropdown-toggle.custom-btn1:hover,
.btn.dropdown-toggle.custom-btn1:focus {
  background-color: #fff;
  border-color: #D4D4D4; 
  color: #000; 
  box-shadow: none; 
}

.btn.dropdown-toggle.custom-btn1:hover {
  background-color: #fff; 
  color: #000; 
}


.list {
  padding: 0;
  font-family: 'Kingred Modern';
  font-weight: 400;
  font-size: 14px;
  /* line-height: 35.35px; */
}

.scrollable-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.full-width-line {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 90%; 
  border: none;
  height: 2px; 
  background-color: rgba(0, 0, 0, 0.2); 
}

.accordion-buttonn {
  font-family: 'Kingred Modern';
  font-size: 18px;
  /* font-weight: 500; */
  line-height: 19.07px;
  text-align: left;
  color: #000000;
}

/* #filterAccordion {
  display: none;
} */

.productlisting-indexSort {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}


.filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.filter {
  font-family: 'Kingred Modern';
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: left;
  color: black;
  margin-right: 10px;
}

.filter-btn {
  /* width: 20px;
  height: 20px; */
  display: inline-block;
  position: relative;
  left: -19px;
  border: none;
  background: transparent;
  color:#ffd404;
}

.productlisting-sort {
  position: relative;
  right: -12px;
  border: 1px solid gray;
}

.filterGrid {
  width: 283px;
  margin-left: -20px;
}

.filterPage {
  display: none;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.353);
  position: absolute;
  top: 0;
  z-index: 999;
}

.filterspc {
  position: relative;
  width: 1241.57px;
  left: -20px;
  top: 31px;
}
.clear-all-btn{
  position: relative;
  top: 30px;
  left: -325px;
  border: none;
  background: transparent;
  color: #790101;
}

.custom-btn1 {
  background-color: initial; 
  /* border: 1px solid gray; */
  border: none;
  color: #000; 
}

.custom-btn1:hover {
  background-color: inherit;
  color: #000;
}

.list-group-item {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 10px; 
}

.form-check-input {
  margin-right: 10px; 
}

.form-check-label {
  font-family: 'Kingred Modern';
  font-size: 14px;
  font-weight: 400;
  line-height: 35.35px;
  color: #000000;
}
.no-data-container {
  text-align: center;
  margin-top: 50px;
  color: #6c757d;
}

.no-data-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.no-data-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.reset-filters-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffd404;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.accordion-header span{
  font-size: 18px;
  padding-left: 10px;
  font-family: 'Kingred Modern';
  line-height: 19.07px;
}

.btn-close-filter {
  display: none;
}
.selected-filters-container {
  margin-bottom: 20px;
}

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-chip {
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.filter-chip span {
  margin-right: 5px;
}

.remove-filter-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.price-range-slider {
  width: 100%;
  padding: 10px 0;
}

.slider-track {
  position: relative;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}

.slider-track-inner {
  position: absolute;
  top: 0;
  height: 100%;
  background: #2c3e50;
  border-radius: 4px;
}

.price-range-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.bi-chevron-up,
.bi-chevron-down {
  transition: transform 0.3s ease;
}

.bi-chevron-up {
  transform: rotate(180deg);
}

.bi-chevron-down {
  transform: rotate(0deg); 
}

.dropdown-menu {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.fillicon{
  margin-bottom: 5px;
}


@media (max-width: 768px) {
  .productlisting-indexSort {
    flex-direction: column;
  }

  .clear-all-btn {
    width: 100%;
    margin-top: 20px;
  }

  .productlisting-sort {
    right: 24px;
    height: 50%;
  }

  .card__thumbnail img {
    position: absolute;
    height: 411px;
  }

  .filter-container {
    margin-bottom: 10px;
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 20px;
  }
  .accordion {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
  }

  .product-list .row {
    display: block;
    margin: 0 auto;
  }
  .clear-all-btn {
    position: relative;
    top: 20px;
    left: -120px;
    border: none;
    background: transparent;
    color: #ffd404;
  }
  .filter-btn {
    left: 13px;
    cursor: pointer;
  }

  .card__thumbnail {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    min-height: 411px;
  }

  .card__thumbnail img {
    width: 100% !important;
  }
  .card__body {
    width: 100% !important;
    padding: 10px;
    height: 183.59px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: -250px; 
    width: 250px;
    height: 100vh;
    background-color: #fff;
    transition: left 0.3s ease;
    z-index: 9999;
  }

  .sidebar.open {
    left: 0; 
  }

  .overlay {
    display: none;
  }

  .overlay.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }

  .btn-close-filter {
    background-color: #ffd404;
    color: #fff;            
    border: none;          
    font-size: 16px;        
    font-weight: 600;       
    display: flex;         
    align-items: center;    
    justify-content: center; 
    height: 50px;           
    width: 100%;            
    padding: 0;             
    cursor: pointer;      
    position: relative;     
    z-index: 1000;    
    font-family: 'Kingred Modern';
  }
  
  .btn-close-filter i {
    font-size: 16px;        
    margin-right: 80px;    
  }
  
  /* .btn-close-filter:hover {
    background-color: #333; 
  } */
  
  .sidebar.open .btn-close-filter {
    position: absolute;
    top: 0;                 
    left: 0;                
    z-index: 9999;           
  }
  
}

