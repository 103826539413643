.checkout-bg {
  height: 40vh;
  top: -1px;
  padding: 150px;
  background: radial-gradient(70.37% 105.56% at 73.01% 31.97%, #EEF7FD 5%, #FBEED3 75%, #FFEBC5 100%);
}

.checkout-page {
  padding: 20px;
  font-family: 'Kingred Modern';
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.checkout-card h1,
.checkout-card h2 {
  font-family: 'Kingred Modern';
  font-weight: 500;
  font-size: 1.5rem;
}

.checkout-card {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  display: flex;
  gap: 20px;
  max-width: 1240px;
  width: 100%;
  margin-top: -80px;
  margin-bottom: 50px;
  box-shadow: 0px 37px 90px 0px #00000017;
}

.shipping-address {
  flex: 0.65; 
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: auto; 
  position: relative;
}

.cart-summary {
  flex: 0.35; 
  background: #FEF7EC;
  padding: 15px;
  position: relative; 

}

.order-summary {
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 50px;
  margin-top: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  font-family: 'Kingred Modern';
}

.order-summary p {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}


.order-summary p span:last-child {
  font-weight: 600;
  color: #333;
}

.order-summary .total {
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
  border-top: 1px solid #eaeaec;
  padding-top: 15px;
}

.payment-mode-wrapper {
  margin-bottom: 50px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.payment-mode-wrapper h3 {
  font-family: 'Kingred Modern';
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.payment-mode {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.payment-label {
  font-size: 16px;
  color: #555;
  margin-left: 10px;
  cursor: pointer;
}

.payment-mode label:hover {
  color: #ffd404;
}

.payment-mode input[type="radio"] {
  accent-color: #000;
  margin-right: 10px;
}


.checkout-button {
  width: 100%;
  background: #ffd404;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 1rem;
  margin-top: 10px;
  position: absolute;
  bottom: 0; 
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-address-button {
  width: 100%;
  color: #ffd404;
  background: transparent;
  border: 1px dashed #f1c40f;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  text-align: left;
}

.address-card {
  position: relative;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Kingred Modern';
  padding-left: 60px;
}

.address-card p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 20px;
  text-transform: capitalize;
}

.radio-label {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: flex-start; 
}

.radio-input {
  margin-right: 10px;
  cursor: pointer;
  margin-top: 3px; 
  accent-color: #000
}

.address-title {
  font-size: 12px;
  font-weight: 700;
  margin: 12px 0 16px;
  color: #535766;
}


.delete-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: rgb(255, 72, 66);
  background: transparent;
}

.delete-btn:hover {
  color: #f44336;
  background-color: rgba(255, 72, 66, 0.1);
  box-shadow: 0 4px 8px rgba(255, 72, 66, 0.2);
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Modal container */
.modal-content-pro {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: 'Kingred Modern';
  animation: fadeIn 0.3s ease-out;
  z-index: 10000;
}

/* Modal fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Modal heading */
.modal-content-pro h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: 600;
  font-family: 'Kingred Modern';
}

/* Form group styling */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 1rem;
  color: #666;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Kingred Modern';
  outline: none;
  transition: border 0.2s ease;
}

.form-group input:focus {
  border-color: #ffd404;
}

/* Error text */
.error-text {
  color: #f44336;
  font-size: 0.875rem;
  margin-top: 5px;
  text-align: left;
}

/* Buttons */
.modal-content-pro .btns {
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  font-family: 'Kingred Modern';
  transition: background-color 0.2s ease;
}

.modal-content-pro .btn-primarys {
  background-color: #ffd404;
  color: #000;
}

.modal-content-pro .btn-primarys:hover {
  background-color: #d4a853;
}

.modal-content-pro .btn-secondaryc {
  background-color: #ddd;
  color: #333;
  margin-top: 15px;
}

.modal-content-pro .btn-secondaryc:hover {
  background-color: #ccc;
}

/* Close button */
.modal-content-pro .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  transition: color 0.2s ease;
}

.modal-content-pro .close-btn:hover {
  color: #ffd404;
}

.user-name {
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #282c3f;
  word-break: break-all;
  font-family: "Kingred Modern";
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .checkout-card {
    flex-direction: column-reverse; 
  }

  .shipping-address,
  .cart-summary {
    flex: 1;
  }
}


