.top-nav {
  background-color: rgba(38, 38, 38, 1);
  color: rgba(255, 255, 255, 1);
  font-family: 'Kingred Modern';
  font-size: 10px;
  font-weight: 400;
  line-height: 12.55px;
  letter-spacing: 0.345em;
  height: 26px;
  /* text-align: left; */
}

.logo {
  padding-top: 0rem !important;
  position: relative;
  right: 100px;
  top: 5px;
}

#google_translate_element {
  display: block !important;
  position: relative;
  left: 120px;
  top: -11px;
}

.goog-te-combo {
  padding: 5px;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 1rem;
}

#navbarNav {
  position: relative;
  left: 30px;
  top: 30px;
}

.tooltip-container a:hover,
.dropdown-menu a:hover {
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #790101;
  transition: transform 0.3s ease-in-out;
  z-index: 99;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.hide {
  transform: translateY(-100%);
}

.nav-item {
  position: relative;
  padding: 0.5rem 1rem;
}

.navbar-nav .nav-link {
  position: relative;
  font-family: 'Kingred Modern';
  font-weight: 500;
  font-size: 16px;
  line-height: 23.34px;
}

.nav-item .nav-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 100%;
  background-color: #ffd404;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav-item:hover .nav-link:after {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-item .submenu {
  width: 200px;
  display: none;
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.nav-item .submenu {
  list-style-type: none;
}

.nav-item:hover .submenu {
  display: block;
}

.submenu li {
  margin-bottom: 0.5rem;
  /* font-size: 20px; */
}

.submenu li:last-child {
  margin-bottom: 0;
}

.submenu li a {
  color: #333;
  text-decoration: none;
}

.submenu li a:hover {
  color: #790101;
}


.whiteText {
  color: white !important;
}

.blackText {
  color: #ffd404;
}
.blackText:hover {
  color: #ffd404;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #ffd404;
}

.navbar-nav {
  margin-bottom: 52px !important;
}

.navbar-nav .nav-item {
  margin: 0 1rem;
}

.navbar-toggler {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 40px;
  /* background-color: red !important; */
  transition: 0.5s ease-in-out !important;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}

.navbar-toggler span {
  /* margin: 5px 0; */
  padding: 0;
}

.toggler-icon {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  opacity: 1;
  background-color: black;
  transform: rotate(0deg) !important;
  transition: 0.25s ease-in-out;
  margin: 5px 0 !important;
}

.middle-bar {
  margin-top: 0;
}

/* when clicked */
.navbar-toggler .top-bar {
  margin-top: 15px !important;
  transform: rotate(135deg) !important;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  margin-top: -20px !important;
  transform: rotate(-135deg) !important;
}

/* when collapsed */
.navbar-toggler.collapsed .top-bar {
  margin-top: 0px !important;
  transform: rotate(0deg) !important;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  width: 80%;
  position: relative;
  left: 6px;
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 4px !important;
  transform: rotate(0deg) !important;
  width: 50%;
  position: relative;
  left: 13px;
}

.right-nav {
  margin-top: 90px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  top: 1px;
  right: -20px;
}

.globe-icon {
  position: relative;
  top: -3px;
  right: -60px;
}

/* Cart Icon */
.cart-icon {
  position: relative;
  /* margin-left: 80px;  */
  z-index: 100; 
}

.cart-icon img {
  height: 24px; 
  width: auto;
}

.cart-icon span {
  position: absolute;
  top: -10px;
  right: -10px; 
  background-color: #ffd404;
  border-radius: 50%;
  color: #790101;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.dropdown-menu[data-bs-popper] {
  left: 18px !important;
}

.lang-menu {
  top: 21.33px;
  border: none !important;
}

.custom-btn {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.search-box {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: -10px;
  color: #ffd404;
  background-color: transparent;
  z-index: 1;
}

.input-search {
  height: 50px;
  width: 50px;
  /* border: 2px solid #ffd404; */
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  /* background-color: #ffd404; */
  background-color: transparent;
  padding-right: 40px;
  color: #790101;
  position: relative;
  right: 3px;
  top: -10px;
  cursor: pointer;
}

.input-search::placeholder {
  color: #790101;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 2px;
}

.input-search:focus {
  width: 300px;
  cursor: text;
  background-color: #ffd404;
  transition: all 0.5s ease-in-out;
  padding-right: 40px;
}

.input-search.open {
  width: 300px;
  cursor: text;
  background-color: #ffd404;
  transition: all 0.5s ease-in-out;
  padding-right: 40px;
}

.search-results {
  position: absolute;
  top: 55px;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  z-index: 1000;
}

.search-results a {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  color: #333;
}

.search-results a:hover {
  background-color: #f5f5f5;
}
.navbar-nav .nav-link {
  position: relative;
}

.dropdown a:active {
  outline: none;
  box-shadow: none;
  border: none;
}

.navbar-nav li a {
  font-family: 'Kingred Modern';
  font-weight: 500;
  font-size: 16px;
  line-height: 23.34px;
}

.navbar-nav {
  font-family: 'Kingred Modern';
  font-weight: 700;
  size: 12px;
  line-height: 32.34px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
}

.modal-content iframe {
  width: 100%;
  height: 100%;
}

.close-buttonn {
  position: absolute;
  top: 14px;
  right: 23px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.close-buttonn:hover {
  color: #ff0000;
}

