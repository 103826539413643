.dash-top-section {
    height: 390px;
    width: 100%;
    background: radial-gradient(70.37% 105.56% at 71.01% 57.97%,
            #FFFFFF 0%,
            #FBEBD3 74%,
            #FBEBD3 100%);
}

.orc {
    width: 847px;
    max-width: 900px; 
    margin: 0 auto; 
    background: white;
    padding: 42px 23px;
}

.orc h1 {
    font-family: "Noto Serif Display";
    font-size: 18px;
    font-weight: 500;
    line-height: 24.52px;
    text-align: left;
    color: black;
}

.orderCardList {
    overflow-y: scroll;
    max-height: 490px;
    scrollbar-width: thin;
}

.itemsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
}

.orderCardItem {
    font-size: 16px;
    width: 100%;
    /* Responsive width */
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    font-family: 'Mulish';
    background: white !important;
    /* box-shadow: 0 37px 90px rgba(0, 0, 0, 0.09); */
}

.orderCardItem h3 {
    font-size: 25px;
    font-family: "Noto Serif Display";
}

.orderSummary {
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.productDetails {
    margin-top: 10px;
    padding-left: 20px;
    border-radius: 8px; 
}
.productCardItem {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    margin-bottom: 20px;
    font-family: 'Mulish';
}

/* .productCardItem {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    margin-bottom: 20px;
    font-family: 'Mulish';
} */

.productContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contentOne,
.contentTwo {
    display: flex;
    gap: 20px;
    align-items: center;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.pagination-btn {
    padding: 10px 20px;
    border: none;
    background-color: #EDBD67;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.pagination-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.productImage {
    width: 104px;
    height: 104px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 15px;
}

.productName {
    width: 204px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.84px;
    text-align: left;
    margin: 0 0 5px 0;
}

.productRightContain {
    display: flex;
    gap: 85px;
    align-items: center;
}

.productPrice,
.productQuantity,
.productTotal {
    text-align: center;
}

.productPrice p,
.productQuantity p,
.productTotal p {
    margin: 0;
}

.horizontalLine1 {
    border: 0;
    height: 1px;
    background-color: #ddd;
    margin-bottom: 10px;
}

.horizontalLine {
    border: 1px solid #ddd;
    height: 1px;
    background-color: #ddd;
}

.verticalLine {
    width: 2px;
    height: 21px;
    background-color: #ddd;
    margin: 0 15px;
}


.contentTwo {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.contentTwo p {
    margin: 0;
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
    .orc {
        width: 100%; 
        max-width: 100%;
        margin: 20px 0 0 0;
        padding: 15px;
        box-sizing: border-box;
    }
    
    .userActflex {
        flex-direction: column;
        align-items: flex-start; 
    }

    .myAccountBox {
        width: 100%; 
        margin-bottom: 15px; 
    }
    .orderCardList {
        overflow-y: none;
        max-height: none;
        scrollbar-width: thin;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .orc {
        width: 85%;
        margin: 0 auto;
    }

    .productCardItem {
        display: flex;
        flex-direction: row; 
        align-items: center;
        gap: 20px; 
    }

    .productName {
        font-size: 18px;
    }
    
    .productRightContain {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    .contentOne {
        display: flex;
        flex-direction: row; 
        gap: 10px;
    }

    .orderCardList {
        overflow-y: none;
        max-height: none;
        scrollbar-width: thin;
    }
    .orderCardList {
        overflow-y: scroll;
        max-height: 490px;
        scrollbar-width: thin;
    }
}

@media only screen and (min-width: 1224px) {
    .dash-top-section {
        height: 390px;
        width: 100%;
        background: radial-gradient(70.37% 105.56% at 71.01% 57.97%, #FFFFFF 0%, #FBEBD3 74%, #FBEBD3 100%);
    }

    .orc {
        width: 847px;
        max-width: 900px;
        margin: 0 auto;
        background: white;
        padding: 42px 23px;
    }

    .orc h1 {
        font-family: "Noto Serif Display";
        font-size: 18px;
        font-weight: 500;
        line-height: 24.52px;
        text-align: left;
        color: black;
    }

    .orderCardList {
        overflow-y: scroll;
        max-height: 490px;
        scrollbar-width: thin;
    }

    .itemsContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 24px;
    }
}

@media only screen and (min-width: 1824px) {
    .orderCardItem {
        font-size: 16px;
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        cursor: pointer;
        font-family: 'Mulish';
        background: white !important;
    }

    .orderCardItem h3 {
        font-size: 25px;
        font-family: "Noto Serif Display";
    }

    .orderSummary {
        padding: 15px;
        margin-bottom: 15px;
        background-color: #f9f9f9;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .productDetails {
        margin-top: 10px;
        padding-left: 20px;
        border-radius: 8px;
    }

    .productCardItem {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
        font-family: 'Mulish';
    }

    .productContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .contentOne,
    .contentTwo {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }

    .pagination-btn {
        padding: 10px 20px;
        border: none;
        background-color: #EDBD67;
        color: white;
        cursor: pointer;
        border-radius: 5px;
    }

    .pagination-btn:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    .productImage {
        width: 104px;
        height: 104px;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 15px;
    }

    .productName {
        width: 204px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.84px;
        text-align: left;
        margin: 0 0 5px 0;
    }

    .productRightContain {
        display: flex;
        gap: 85px;
        align-items: center;
    }

    .productPrice,
    .productQuantity,
    .productTotal {
        text-align: center;
    }

    .productPrice p,
    .productQuantity p,
    .productTotal p {
        margin: 0;
    }

    .horizontalLine1 {
        border: 0;
        height: 1px;
        background-color: #ddd;
        margin-bottom: 10px;
    }

    .horizontalLine {
        border: 1px solid #ddd;
        height: 1px;
        background-color: #ddd;
    }

    .verticalLine {
        width: 2px;
        height: 21px;
        background-color: #ddd;
        margin: 0 15px;
    }

    .contentTwo {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
    }

    .contentTwo p {
        margin: 0;
    }
}

