.terms-of-service-container {
    margin: 20px auto;
    padding: 120px 20px 40px 20px;
    font-family: "Kingred Modern";
}

.terms-of-service-container h2 {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.terms-of-service-content {
    /* max-height: 600px;
    overflow-y: scroll; */
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

.terms-of-service-content h3 {
    font-size: 1.5rem;
    margin-top: 15px;
    color: #333;
    border-left: 4px solid #790101;
    padding-left: 10px;
}

.terms-of-service-content p {
    margin-bottom: 10px;
}
.terms-of-service-content a {
    color: #790101;
    text-decoration: none;
    font-weight: bold;
}


/* Scrollbar styling */
.terms-of-service-content::-webkit-scrollbar {
    width: 8px;
}

.terms-of-service-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.terms-of-service-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}
