@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
  
}

body{
  background-color: white !important;
  overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0px;
}