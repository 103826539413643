.dash-container {
  width: 1240px;
  margin: 0 auto;
  margin-top: -170px;
  /* margin-bottom: -100px; */
  padding: 0 !important;
  box-shadow: 0px 37px 90px 0px #00000017;
  margin-bottom: 85px;
}

.d-containt {
  width: 847px;
  background: white;
  padding: 42px 72px 0px 57px;
}

.details-container {
  width: 100%;
}

.detail {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.delete-icon {
  margin-left: auto;
  cursor: pointer;
  color: red;
}

.delete-icon:hover {
  color: darkred;
}

.icon {
  width: 40px;
  margin-right: 15px;
}

.update-icon-button {
  font-size: 10px;
}

.info {
  display: flex;
  flex-direction: column;
}

.dashTitle {
  font-family: "Kingred Modern";
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 15.06px !important;
  text-align: left !important;
  color: #5e5e5e !important;
  margin-bottom: 5px !important;
  width: 130px;
}

.value {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 600;
  line-height: 20.08px;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 5px;
  border: none;
  /* text-transform: capitalize; */
}

.value:focus {
  outline: none;
  box-shadow: none;
}

.value:focus {
  outline: none;
  border-bottom: 1px solid #ffd404;
}

.dhr {
  margin-top: 34px;
  margin-bottom: 34px;
  display: block;
}

.btn-myAccount {
  display: none;
}

.address-container {
  margin-top: 20px;
  max-height: 218px;
  overflow-y: auto;
  padding-right: 10px;
  position: relative;
  /* padding-top: 40px;  */
}

.address-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  pointer-events: none;
}

.address-container::-webkit-scrollbar {
  width: 8px;
}

.address-container::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
}

.address-container::-webkit-scrollbar-thumb:hover {
  background-color: #d0d0d0;
}

.address-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.address-container .detail {
  margin-bottom: 15px;
}

.add-address-buttonn {
  width: 200px;
  height: 50px;
  color: #ffd404;
  background: transparent;
  border: 1px solid #f1c40f;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
}

.add-address-buttonn:hover {
  background-color: rgba(241, 196, 15, 0.1);
  box-shadow: 0 4px 8px rgba(241, 196, 15, 0.3);
}

.address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.no-address-message {
  font-size: 16px;
  color: #888;
  margin-top: 10px;
  text-align: center;
}

.text-start-pro {
  position: relative;
}

.text-start-pro h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit-button-update {
  border: none;
  background: transparent;
  color: #ffd404;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .btn-myAccount {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .dhrn {
    display: none;
  }

  .d-containt {
    width: 85%;
    margin: 0 auto;
    padding: 24px;
  }

  .d-containt h1 {
    margin-bottom: 22px;
  }

  .userActflexx {
    flex-wrap: wrap;
  }

  .add-address-buttonn {
    padding: 2px 14px;
    margin-bottom: 15px;
    margin-left: 9px;
  }
}

@media only screen and (max-width: 768px) {
  .userActflexx {
    flex-wrap: wrap;
  }
  .dash-container {
    width: 100%;
  }
  .d-containt {
    width: 85%;
    margin: 0 auto;
    padding: 24px;
    box-shadow: 0px 37px 90px 0px #00000017;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-myAccount {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .dash-container {
    width: 85%;
  }

  .dhrn {
    display: none;
  }

  .userMailspace {
    flex-direction: column;
  }

  .d-containt {
    width: 85%;
    margin: 0 auto;
    padding: 24px;
    box-shadow: 0px 37px 90px 0px #00000017;
  }

  .userActflexx {
    flex-wrap: wrap;
  }

  .d-containt h1 {
    margin-bottom: 22px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1223px) {
  .dash-container {
    width: 85%;
    margin: 0 auto;
    margin-top: -120px;
    margin-bottom: 85px;
  }

  .d-containt {
    width: 100%;
    padding: 42px 72px 0px 57px;
  }

  .userActflexx {
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 1224px) {
  .dash-container {
    width: 85%;
    margin: 0 auto;
    margin-top: -120px;
    margin-bottom: 85px;
  }

  .d-containt {
    width: 100%;
    padding: 42px 72px 0px 57px;
  }

  .userActflexx {
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 1824px) {
  /* Additional styles for larger screens */
  .dash-container {
    width: 1240px;
    margin: 0 auto;
    margin-top: -170px;
    margin-bottom: -100px;
    padding: 0 !important;
    box-shadow: 0px 37px 90px 0px #00000017;
  }

  .d-containt {
    width: 847px;
    background: white;
    padding: 42px 72px 0px 57px;
  }

  .details-container {
    width: 100%;
  }
}
