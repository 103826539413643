.festival-kurtas-container {
  padding: 30px;
  text-align: left;
}

.section-heading {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  font-family: 'Kingred Modern';
  text-transform: uppercase;
}

.kurta-item {
  width: 100%;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
}

.kurta-item img {
  width: 100%;
  height: 421px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.kurta-item:hover img {
  transform: scale(1.05);
}

.kurta-details {
  margin-top: 10px;
}

.kurta-name {
  font-size: 1.2em;
  color: #fff;
  font-family: 'Kingred Modern';
  margin: 0;
}

.kurta-price {
  font-size: 1em;
  color: #fff;
  margin: 5px 0 0 0;
}
