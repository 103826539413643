.login-top-section {
  width: 100%;
  padding: 200px 0;
  background: radial-gradient(
    70.37% 105.56% at 71.01% 57.97%,
    #ffffff 0%,
    #fbebd3 74%,
    #fbebd3 100%
  );
}


.login-container {
  width: 860px;
  padding: 0px 0;
  background-color: #ffffff;
  margin: 0 auto;
  display: flex;
  border: 1px solid #ccc;
  position: relative;
  margin-top: -145px;
  margin-bottom: 55px;
  flex-direction: row;
}

.switch-btn {
  display: block;
  /* padding: 10px 20px; */
  background-color: #fff;
  color: #ffd404;
  text-decoration: underline;
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 73%;
  transform: translateX(-50%);
}

.right-side {
  text-align: center;
}

.left-side {
  width: 393px;
  background: #fef7ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.left-side img {
  max-width: 170px;
  margin-bottom: 20px;
}

.left-side h2 {
  font-family: "Kingred Modern";
  font-size: 30px;
  font-weight: 500;
  line-height: 24.52px;
  text-align: left;
}

.right-side {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-side h2 {
  font-family: "Kingred Modern";
  font-size: 30px;
  font-weight: 500;
  line-height: 24.52px;
  text-align: left;
  padding: 20px;
}

.input-containerr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-input {
  width: 310px;
  height: 40px;
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  background: #f4f4f4;
  font-family: "Kingred Modern";
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  text-align: left;
  outline: none;
}

.COntinue-btn {
  width: 310px;
  height: 40px;
  background-color: #ffd404;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Kingred Modern";
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.otp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden; 
}

body.modal-open {
  overflow: hidden; 
}

.otp-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 510px;
  max-width: 90%; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); 
}


.otp-modal-content h2 {
  font-family: "Kingred Modern";
  font-size: 30px;
  font-weight: 500;
  line-height: 24.52px;
  padding: 20px;
}

.close-iconn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}

.otp-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.otp-input {
  width: 60px;
  height: 60px;
  margin: 0 10px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.otp-actions span {
  display: block;
  margin-top: 10px;
  text-align: center;
  font-family: "Kingred Modern";
  font-size: 14px;
}

.submit-otp-btnn {
  width: 200px;
  height: 50px;
  background-color: #ffd404;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Kingred Modern";
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.resend-linkk {
  color: #ffd404;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  text-align: center;
  font-family: "Kingred Modern";
  font-size: 14px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .login-top-section {
    padding: 130px 0;
  }

  .login-container {
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .left-side,
  .right-side {
    width: 100%;
    height: auto;
    padding: 70px 20px;
  }

  .switch-btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
  }

  .mobile-input,
  .COntinue-btn {
    width: 260px;
  }

  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 5px;
  }

  .otp-modal-content {
    width: 400px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .login-top-section {
    padding: 150px 0;
  }

  .login-container {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin-top: -45px;
    margin-bottom: 50px;
  }

  .left-side,
  .right-side {
    width: 100%;
    padding: 60px 30px;
  }

  .switch-btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
  }

  .mobile-input,
  .COntinue-btn {
    width: 280px;
  }

  .otp-input {
    width: 55px;
    height: 55px;
  }

  .otp-modal-content {
    width: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .login-container {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin-top: -100px;
    margin-bottom: 50px;
  }

  .left-side,
  .right-side {
    width: 100%;
    padding: 60px 30px;
  }

  .switch-btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
  }

  .mobile-input,
  .COntinue-btn {
    width: 280px;
  }

  .otp-input {
    width: 55px;
    height: 55px;
  }

  .otp-modal-content {
    width: 450px;
  }
}
@media only screen and (min-width: 1224px) {
  .login-container {
    width: 860px;
    /* height: 600px; */
    margin-top: -100px;
  }

  .left-side,
  .right-side {
    width: 50%;
    padding: 40px 50px;
  }

  .switch-btn {
    bottom: 5px;
    left: 70%;
  }

  .mobile-input,
  .COntinue-btn {
    width: 310px;
  }

  .otp-modal-content {
    width: 500px;
  }
}
@media only screen and (min-width: 1824px) {
  .login-container {
    width: 1000px;
  }

  .left-side,
  .right-side {
    padding: 70px 60px;
  }

  .switch-btn {
    bottom: 30px;
    left: 75%;
  }

  .mobile-input,
  .COntinue-btn {
    width: 340px;
  }

  .otp-modal-content {
    width: 550px;
  }
}
