/* Container Style */
.contact-us-container {
    max-width: 100%;
    padding: 120px 20px 40px 20px;
    background-color: #f7f7f7;
    color: #444;
    line-height: 1.8;
  }
  
  /* Header Styles */
  .contact-us-container h1 {
    font-family: "Kingred Modern";
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #222;
    text-align: center;
  }
  
  /* Paragraph Styles */
  .contact-us-container p {
    font-family: "Kingred Modern";
    margin-bottom: 20px;
    font-size: 1.1em;
    color: #555;
    border-left: 4px solid #790101;
    padding-left: 10px;
  }
  
  .contact-us-container a {
    color: #790101;
  }
  
  /* Responsive Design Adjustments */
  @media (max-width: 768px) {
    .contact-us-container h1 {
      font-size: 2.2em;
    }
  
    .contact-us-container p {
      font-size: 1em;
    }
  }
  