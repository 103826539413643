.cart-banner {
  height: 40vw;
  background: radial-gradient(
    70.37% 105.56% at 71.01% 57.97%,
    #ffffff 0%,
    #fbebd3 74%,
    #fbebd3 100%
  );
  text-align: center;
}

.cart-main {
  position: relative;
  top: -270px;
}

.cart-list-container {
  height: 100%;
  overflow-y: auto;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 37px 90px 0px rgba(0, 0, 0, 0.09);
}

.cart-order-summary-second {
  display: none;
}

.cart-head-title h1 {
  font-family: "Kingred Modern";
  font-size: 40px;
  font-weight: 700;
  line-height: 50.8px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
}

.cart-offer {
  padding: 10px;
  background: linear-gradient(
    90.17deg,
    #ffd5d4 20.32%,
    #ffefef 56.79%,
    #ffe3e2 94.36%
  );

  gap: 9px;
  margin-bottom: 30px;
}

.cart-offer p {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 600;
  line-height: 20.86px;
  text-align: left;
  color: rgba(38, 38, 38, 1);
}

.cart-list {
  flex: 1;
  overflow-y: auto;
  padding-right: 20px;
}

.cart-list-items {
  /* width: 847px; */
  padding: 41px 27px 0px 45px;
}

.cart-list-items h6 {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 500;
  line-height: 20.86px;
  text-align: left;
  color: rgba(100, 100, 106, 1);
}

.price-head {
  left: 410px;
}

.quantity-head {
  left: 530px;
}

.totla-head {
  left: 658px;
}

.cart-card {
  display: flex;
  padding-top: 36px;
  gap: 20px;
}

.cart-img {
  width: 124px;
  height: 124px;
}

.cart-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.cart-details {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.cart-title-desc {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
}

.cart-title-desc h3 {
  font-family: "Kingred Modern";
  font-size: 18px;
  font-weight: 500;
  line-height: 26.82px;
  color: rgba(0, 0, 0, 1);
  padding-right: 10px;
}

.cart-remove-option {
  text-decoration: none;
  font-family: "Kingred Modern";
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 67, 67, 1);
  cursor: pointer;
}

.cart-values {
  display: flex;
  flex-direction: column;
  /* width: 50%; */
}

.labels-row {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  color: #888;
  margin-bottom: 30px;
}

.values-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.price,
.quantity,
.total {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 500;
  line-height: 20.86px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.total {
  text-align: right;
}

.tax-included {
  display: block;
  font-size: 12px;
  color: #888888;
  margin-top: 4px;
}

.quantity {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantity input {
  width: 40px;
  text-align: center;
  border: 1px solid rgba(197, 197, 197, 1);
  border-radius: 4px;
  padding: 0;
  background-color: rgba(233, 233, 233, 1);
}

.minus,
.plus {
  cursor: pointer;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.minus:hover,
.plus:hover {
  background-color: rgba(200, 200, 200, 0.5);
}

.minus:active,
.plus:active {
  background-color: rgba(150, 150, 150, 0.5);
}

.card-divider {
  margin-top: 36px !important;
  height: 1px;
  background-color: rgba(214, 214, 214, 1);
  /* border: 0.5px solid rgba(214, 214, 214, 1) !important; */
}

/* .empty-cart-message {
  text-align: center;
  padding: 20px;
  font-family: "Kingred Modern";
} */

.empty-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;  
  top: -50px;
  text-align: center;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Kingred Modern";
}

.empty-cart-message {
  margin-top: 10px;
}

/* ==========================order summery======================= */

.cart-order-summary {
  position: sticky;
  background-color: rgba(254, 247, 236, 1);
  display: flex;
  flex-direction: column;
  padding: 34px;
  position: relative;
}

.cart-order-summary hr {
  margin: 20px 0 !important;
}

.summary-title {
  font-family: "Kingred Modern";
  font-size: 26px;
  font-weight: 500;
  line-height: 35.41px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.calculation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
}

.calculation h3,
.calculation span {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 500;
  line-height: 20.86px;
  color: rgba(0, 0, 0, 1);
}

.Total h3,
.Total span {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 700;
  line-height: 23.84px;
  color: rgba(0, 0, 0, 1);
}

.code-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 32px;
}

.promocode {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 13px;
  margin-bottom: 50px;
}

.promocode h3 {
  font-family: "Kingred Modern";
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  color: rgba(0, 0, 0, 1);
}

.promocode input {
  width: 315px;
  height: 45px;
  padding: 11px 0px 13px 23.3px;
  border: none;
  border-radius: 130px;
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 500;
  line-height: 20.86px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.promocode button {
  /* height: 35px; */
  width: 103.31px;
  padding: 8px 26.9px 9px 32.41px;
  border: none;
  background-color: rgba(38, 38, 38, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 130px;
  position: absolute;
  top: 4px;
  bottom: 4px;
  right: 15.08px;
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  text-align: left;
}

.promocode input:focus-visible,
.promocode input:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.gift-wrapper {
  width: 314.89px;
  height: 185px;
  position: relative;
}

.gift-wrapper::before {
  content: url("../Assets/ribion.png");
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.wrapper-content {
  position: absolute;
  top: 23px;
  left: 60px;
  right: 27.89px;
  bottom: 29px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 12px;
}

.wrapper-content h3 {
  font-family: "Kingred Modern";
  font-size: 14px;
  font-weight: 600;
  line-height: 20.86px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.wrapper-content p {
  font-family: "Kingred Modern";
  font-size: 12px;
  font-weight: 400;
  line-height: 17.88px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.wrapper-content button {
  width: 176px;
  height: 35px;
  border-radius: 130px;
  border: 1px solid rgba(0, 0, 0, 1);
  color: rgba(38, 38, 38, 1);
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
}

.checkout-btn {
  width: 100%;
  height: 60px;
  background-color: #ffd404;
  border: none;
  margin-left: -34px !important;
  position: absolute;
  bottom: 0;
}

@media (max-width: 768px) {
  .cart-banner {
    height: 50vw;
}
  .cart-main {
    position: relative;
    top: -60px;
  }

  .empty-cart-container {
    height: 20vh;
    width: 100vw;
  }

  /* .row {
    flex-direction: column;
  } */

  .col-md-8.order-md-1 {
    order: 1;
  }

  .col-md-4.order-md-2 {
    order: 2;
  }

  .cart-head-title h1 {
    font-family: "Kingred Modern";
    font-size: 30px;
    font-weight: 500;
    line-height: 40.83px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}

  .cart-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 15px;
  }

  .cart-img {
    flex-shrink: 0;
    /* width: 80px;
    height: 80px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .cart-details {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }

  .cart-title-desc {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .cart-title-desc h3 {
    font-size: 1rem;
    margin: 0;
  }

  .cart-remove-option {
    color: red;
    /* font-size: 0.9rem; */
    cursor: pointer;
  }

  .cart-values {
    display: flex;
    /* width: 100%; */
    margin-top: 10px;
  }

  .labels-row {
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
    color: #888;
    margin-top: 10px;
    gap: 50px;
  }

  .values-row {
    display: flex;
    justify-content: space-between;
    /* margin-top: 5px; */
    gap: 18px;
    align-items: center;
    flex-wrap: nowrap;
  }

  .quantity {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .quantity input {
    text-align: center;
    width: 25px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .plus,
  .minus {
    cursor: pointer;
    font-size: 1rem;
    color: #333;
  }

  .card-divider {
    border-top: 1px solid #ddd;
    margin: 10px 0;
  }
}

@media (min-width: 761px) and (max-width: 991px) {
  .cart-main {
    position: relative;
    top: -200px;
  }

  .cart-card {
    flex-direction: row;
    padding: 15px;
    gap: 15px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 15px;
  }

  .cart-img {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }

  .cart-details {
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }

  .cart-title-desc {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .cart-title-desc h3 {
    font-size: 1.1rem;
  }

  .cart-remove-option {
    color: rgba(255, 67, 67, 1);
    cursor: pointer;
    font-size: 0.9rem;
  }

  .cart-values {
    flex-direction: column;
    margin-top: 5px;
  }

  .labels-row {
    justify-content: space-between;
    font-size: 0.9rem;
    color: #888;
    margin-top: 10px;
    gap: 40px;
  }

  .values-row {
    justify-content: space-between;
    margin-top: 5px;
    gap: 15px;
    align-items: center;
  }

  .price,
  .quantity,
  .total {
    font-size: 15px;
  }

  .quantity {
    gap: 5px;
  }

  .quantity input {
    width: 30px;
    font-size: 0.9rem;
  }

  .plus,
  .minus {
    font-size: 1.1rem;
  }

  .card-divider {
    margin: 20px 0;
    height: 1px;
    background-color: #ddd;
  }

  .checkout-btn {
    height: 50px;
    font-size: 1rem;
  }

  .summary-title {
    font-size: 22px;
  }

  .calculation h3,
  .calculation span {
    font-size: 13px;
  }

  .Total h3,
  .Total span {
    font-size: 15px;
  }

  .promocode input {
    width: 280px;
    height: 40px;
    font-size: 13px;
  }

  .promocode button {
    width: 70px;
    padding: 7px 20px;
    font-size: 13px;
  }

  .promocode button {
    right: -32.92px;
  }

  .gift-wrapper {
    width: 280px;
    height: 160px;
  }

  .wrapper-content h3 {
    font-size: 13px;
  }

  .wrapper-content p {
    font-size: 11px;
  }

  .wrapper-content button {
    width: 150px;
    height: 30px;
    font-size: 12px;
  }
}
