.shop-category-container {
    padding: 30px;
    text-align: left;
    position: relative;
    /* top: 200px; */
}

.category-heading {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
    font-family: 'Kingred Modern';
    text-transform: uppercase;
}

.category-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 30px;
}

.image-element {
    width: 421px;
    overflow: hidden;
    text-align: center;
    animation: fade-in .5s cubic-bezier(.29, .65, .58, 1) forwards;
}

.image-element img {
    width: 421px;
    height: 632px;
    display: block;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.image-element:hover img {
    transform: scale(1.05);
}

.image-text {
    margin-top: 10px;
    color: #fff;
    font-size: 1.5em;
    text-align: center;
    font-family: 'Kingred Modern';
    text-transform: uppercase;
    letter-spacing: 1px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
