.Footer-main {
  background-color: #790101; 
  color: #fff; 
  font-family: 'Kingred Modern';
}

.footer-link {
  color: #ffd404; 
  text-decoration: none;
  font-size: 16px;
  position: relative; /* Positioning context for the pseudo-element */
  transition: color 0.3s ease;
}

.footer-link::before {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute;
  bottom: 0; /* Position the line at the bottom of the text */
  left: 0;
  width: 0; /* Initially no width */
  height: 2px; /* Line height */
  background-color: #ffd404; /* Line color */
  transition: width 0.3s ease; /* Animate the width */
}
/* 
.footer-link:hover {
  color: #fff; 
} */

.footer-link:hover::before {
  width: 100%; /* On hover, make the width 100% */
}

.social-icon {
  color: #ffd404; 
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  color: #fff;
}

.text-uppercase {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

footer {
  background-color: #790101; 
}

footer .col-md-6, footer .col-lg-3 {
  text-align: center;
}

footer .container p {
  font-size: 14px;
}

footer h5 {
  margin-bottom: 20px;
  font-weight: bold;
}

footer p {
  font-size: 14px;
}

footer .social-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

footer .footer-line {
  border: 0;
  border-top: 2px solid #ffd404; 
  margin: 20px 0;
}

@media (max-width: 768px) {
  footer .col-lg-3 {
    text-align: center;
  }
  footer .social-icon {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  footer h5 {
    font-size: 18px;
  }
  footer .footer-link {
    font-size: 14px;
  }
}
