.myAccountBox {
    background-color: #FEF7EC;
    height: 607px;
    width: 393px !important;
    padding: 42px 57px 42px 47px;

}

.list-group {
    --bs-list-group-color: none;
    --bs-list-group-bg: none;
    padding-top: 27px;
}

.dashLeftTitle {
    font-family: "Kingred Modern";
    font-size: 18px !important;
    font-weight: 500;
    line-height: 24.52px;
    text-align: left;
    color: black;
    
}

.list-group-item+.list-group-item.active {  
    margin-top: 0;
    border-top-width: 0;
}

.list-group-itemum {
    font-family: "Kingred Modern";
    font-size: 14px;
    font-weight: 400;
    line-height: 60px;
    text-align: left;
    color: black;
    padding: 0;
}

.list-group-item.active {
    z-index: 2;
    color: #ffd404;
    background-color: transparent;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.list-group-item+.list-group-item.active {
    border-top-width: none;
    
}

.userMenubtnClass {
    width: 100%;
    display: none;
}


.list-group-itemum, 
.usrMnbtn .dropdown-item {
    background-color: transparent !important;
}

#menu-order:hover {
    background-color: transparent !important;
    border-color: none;
    z-index: 2;
}
.drpUserbtnMyAct {
    width: 196px;
    /* height: 50px; */
    padding: 20px;
    background-color: #ffd404;
    color: #000;
    text-align: left;
    background-color: ffd404;
    font-family: "Kingred Modern";
    font-size: 14px;
    line-height: 17.57px;
    font-weight: 600;
  }
  .dropdown-menu {
    /* width: 100%; */
  }

.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #ffd404;
}


@media only screen and (min-width:481px) and (max-width:900px) {
    .myAccountBox {
        display: none;
    }

    .userMenubtnClass {
        display: block;
    }

    .dropdown-menu {
        width: 85%;
    }

    .drpUserbtnMyAct {
        width: 85% !important;
        display: block;
        margin: 30px auto !important;
        align-items: center !important;
        background: #ffd404 !important;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        display: flex;
        justify-content: space-between;
    }

    .dropdown-menu {
        width: 85%;
      }

    .drpUserbtnMyAct ::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: url("../../Assets/myActIcon.png") !important;
        border: 0px;
        }

    .drpUserbtnMyAct :active {
        background-color: #ffd404;
       
    }

    .usrMnbtn {
        width: 90%;
    }

    .usrMnbtn .dropdown-item.active{
        background-color: #ffd404;
    }
}

@media only screen and (min-width:360px) and (max-width:480px) {

    .myAccountBox {
        display: none;
    }

    .dropdown-menu {
        width: 85%;
    }

    .usrMnbtn {
        width: 90%;
    }

    .usrMnbtn .dropdown-item.active{
        background-color: #ffd404;
    }

    .userMenubtnClass {
        display: block;
        /* width: 100vw; */
        /* height: 100px; */

    }

    .drpUserbtnMyAct {
        width: 85% !important;
        display: block;
        margin: 30px auto !important;
        align-items: center !important;
        background: #ffd404 !important;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        display: flex;
        justify-content: space-between;
    }

    .drpUserbtnMyAct ::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: url("../../Assets/myActIcon.png") !important;
        border: 0px;
        }

    .drpUserbtnMyAct :active {
        background-color: #ffd404 !important;
    }

}

@media only screen and (min-width:481px) and (max-width:900px) {
    .myAccountBox {
        display: none;
    }

    .dropdown-menu {
        width: 85%;
    }

    .usrMnbtn {
        width: 90%;
    }

    .usrMnbtn .dropdown-item.active{
        background-color: #ffd404;
    }

    .userMenubtnClass {
        display: block;
    }

    .drpUserbtnMyAct {
        width: 85% !important;
        display: block;
        margin: 30px auto !important;
        align-items: center !important;
        background: #ffd404 !important;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        display: flex;
        justify-content: space-between;
    }

    .drpUserbtnMyAct ::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: url("../../Assets/myActIcon.png") !important;
        border: 0px;
        }

    .drpUserbtnMyAct :active {
        background-color: #ffd404 !important;
    }


}