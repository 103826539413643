.refund-return-policy-container {
    font-family: "Kingred Modern";
    padding: 120px 20px 40px 20px;
    background-color: #f9f9f9;
  }
  
  .policy-section {
    margin: 0 auto;
    border-radius: 10px;
  }
  
  .policy-title {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .policy-detail {
    margin-bottom: 40px;
  }
  
  .policy-detail h2, .policy-detail h3 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
    border-left: 4px solid #790101;
    padding-left: 10px;
  }
  
  .policy-detail p, .policy-detail ul {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
  }
  
  .policy-detail ul {
    list-style: disc inside;
    margin-left: 20px;
  }
  
  .policy-detail a {
    color: #790101;
    text-decoration: none;
  }
  
  .policy-detail a:hover {
    text-decoration: underline;
  }
  
  .policy-detail strong {
    font-weight: bold;
  }
  
  .policy-detail ul li {
    margin-bottom: 8px;
  }
  
  @media (max-width: 768px) {
    .policy-title {
      font-size: 2em;
    }
  
    .policy-detail h2, .policy-detail h3 {
      font-size: 1.5em;
    }
  
    .policy-detail p, .policy-detail ul {
      font-size: 1em;
    }
  }
  