/* General page styling */
.order-confirmation-page {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f4f6f9; */
    font-family: 'Kingred Modern';
    padding: 20px;
  }
  
  .order-confirmation-popup {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    padding: 40px;
    text-align: center;
    position: relative;
  }
  
  .success-icon {
    font-size: 50px;
    background: #28a745;
    margin-bottom: 20px;
  }
  
  .confirmation-message h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .confirmation-message p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .go-to-order-history-button {
    background-color: #ffd404;
    color: white;
    font-size: 16px;
    padding: 12px 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
  }

  
  .go-to-order-history-button:focus {
    outline: none;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 600px) {
    .order-confirmation-popup {
      padding: 30px;
    }
  
    .success-icon {
      font-size: 40px;
    }
  
    .confirmation-message h2 {
      font-size: 20px;
    }
  
    .confirmation-message p {
      font-size: 14px;
    }
  
    .go-to-order-history-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  