/* Container Style */
.privacy-policy-container {
    max-width: 100%;
    padding: 120px 20px 40px 20px;
    background-color: #f7f7f7;
    color: #444;
    line-height: 1.8;
  } 
  
  /* Header Styles */
  .privacy-policy-container h1 {
    font-family: "Kingred Modern";
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #222;
    text-align: center;
  }
  
  /* Subheader Styles */
  .privacy-policy-container h2 {
    font-family: "Kingred Modern";
    font-size: 1.75em;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #333;
    border-left: 4px solid #790101;
    padding-left: 10px;
  }
  
  /* Paragraph Styles */
  .privacy-policy-container p {
    font-family: "Kingred Modern";
    margin-bottom: 20px;
    font-size: 1.1em;
    color: #555;
  }
  
  /* List Styles */
  .privacy-policy-container ul {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 10px;
    font-size: 1.1em;
  }
  
  /* Link Styles */
  .privacy-policy-container a {
    color: #790101;
    text-decoration: none;
    font-weight: bold;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  
  /* Strong Text Styles */
  .privacy-policy-container strong {
    color: #222;
  }
  
  /* Responsive Design Adjustments */
  @media (max-width: 768px) {
    .privacy-policy-container h1 {
      font-size: 2.2em;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.5em;
    }
  
    .privacy-policy-container p,
    .privacy-policy-container ul li {
      font-size: 1em;
    }
  }
  