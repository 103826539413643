body {
    background: #dcf0fa;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.success-bg{
  width: 100%;
  top: -1px;
  padding: 50px;
  /* background: radial-gradient(70.37% 105.56% at 73.01% 31.97%, #EEF7FD 5%, #FBEED3 75%, #FFEBC5 100%); */
}

.containerr {
    max-width: 600px;
    margin: 20px auto; 
    overflow: hidden;
    position: relative;
}

.printer-top, .printer-bottom {
    border: 6px solid #666666;
    background: #333333;
}

.printer-top {
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    height: 6px;
}

.printer-bottom {
    border-top: 0;
    border-radius: 0 0 6px 6px;
    height: 6px;
}

.paper-container {
    position: relative;
    overflow: hidden;
    height: auto;
}

.paperr {
    background: #ffffff;
    font-family: "Mulish";
    position: relative;
    z-index: 2;
    margin: 0 12px;
    padding: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border-radius: 8px; 
    animation: print 3000ms cubic-bezier(0.68, -0.55, 0.265, 0.9) ;
}

.main-contents {
    padding: 24px;
}

.jagged-edge {
    position: relative;
    height: 20px;
    width: 100%;
    margin-top: -1px;
}

.jagged-edge:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%),
                linear-gradient(-45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%);
    background-size: 16px 40px;
    background-position: 0 -20px;
}

.success-icon {
    text-align: center;
    font-size: 48px;
    height: 72px;
    background: #359d00;
    border-radius: 50%;
    width: 72px;
    margin: 16px auto;
    color: #fff;
}

.success-title {
    font-size: 22px;
    text-align: center;
    color: #666;
    font-weight: bold;
    margin-bottom: 16px;
}

.success-description {
    font-size: 15px;
    line-height: 21px;
    color: #999;
    text-align: center;
    margin-bottom: 24px;
}

.order-details {
    text-align: center;
    color: #333;
    font-weight: bold;
}

.order-number-label {
    font-size: 18px;
    margin-bottom: 8px;
}

.order-number {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 48px;
    font-size: 24px;
    padding: 8px 0;
    margin-bottom: 24px;
}

.complement {
    font-size: 18px;
    margin-bottom: 8px;
    color: #32a852;
}

@keyframes print {
    0% {
        transform: translateY(-90%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes print {
    0% {
        -webkit-transform: translateY(-90%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

.failure-icon {
    text-align: center;
    font-size: 48px;
    height: 72px;
    background: #d9534f;
    border-radius: 50%;
    width: 72px;
    margin: 16px auto;
    color: #fff;
}

.failure-title {
    font-size: 22px;
    text-align: center;
    color: #666;
    font-weight: bold;
    margin-bottom: 16px;
}

.failure-description {
    font-size: 15px;
    line-height: 21px;
    color: #999;
    text-align: center;
    margin-bottom: 24px;
}

.redirect-message {
    font-family: 'Noto Serif Display';
    font-size: 48px;
    line-height: 74px;
    color: #FF6B00;
    display: flex;
    justify-content: center;
    animation: bounce 1s infinite;
}



/* Responsive Styles */
@media (max-width: 768px) {

    .success-icon {
        font-size: 40px;
        width: 56px;
        height: 56px;
    }

    .success-title {
        font-size: 20px;
    }

    .success-description {
        font-size: 14px;
    }

    .order-number {
        font-size: 40px;
    }

    .order-number-label, .complement {
        font-size: 16px;
    }
}

/* Additional media query for even smaller screens */
@media (max-width: 480px) {
    .success-icon {
        font-size: 36px;
        width: 48px;
        height: 48px;
    }

    .success-title {
        font-size: 18px;
    }

    .success-description {
        font-size: 13px;
    }

    .order-number {
        font-size: 36px;
    }

    .order-number-label, .complement {
        font-size: 14px;
    }
}


/* Responsive adjustments for failure page */
@media (max-width: 768px) {
    .failure-icon {
        font-size: 40px;
        width: 56px;
        height: 56px;
    }

    .failure-title {
        font-size: 20px;
    }

    .failure-description {
        font-size: 14px;
    }

    .order-number {
        font-size: 40px;
    }

    .order-number-label, .complement {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .failure-icon {
        font-size: 36px;
        width: 48px;
        height: 48px;
    }

    .failure-title {
        font-size: 18px;
    }

    .failure-description {
        font-size: 13px;
    }

    .order-number {
        font-size: 36px;
    }

    .order-number-label, .complement {
        font-size: 14px;
    }
}

