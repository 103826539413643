.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    visibility: hidden;
    width: 160px;
    background-color: #fff;
    color: #000;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 1;
    top: 120%; /* Adjust based on your icon size */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0; /* For transition effect */
    transition: visibility 0.3s, opacity 0.3s ease-in-out; /* Added opacity transition */
  }
  
  .tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1; /* Make the tooltip fully visible on hover */
  }
  
  .tooltip-content a,
  .tooltip-item {
    display: block;
    padding: 5px 0;
    color: #000;
    text-decoration: none;
  }
  
  .tooltip-content a:hover,
  .tooltip-item:hover {
    text-decoration: none;
    background-color: #f0f0f0; 
  }
  
  .tooltip-divider {
    border-bottom: 1px solid #e0e0e0;
    margin: 5px 0;
  }
  